<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/main-logo.png')"
              max-height="225px"
              max-width="500px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2">Please sign-in to your account and start the transaction</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <div v-if="!loading">
            <v-form ref="form" @keyup.enter='login'>
              <v-text-field
                v-model="id_no"
                outlined
                label="ID #"
                placeholder="GL-0-0000"
                hide-details
                class="mb-3"
                :rules="rules.non_empty_field_rule"
              ></v-text-field>

              <v-text-field
                v-model="password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Password"
                placeholder="············"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details
                @click:append="isPasswordVisible = !isPasswordVisible"
                :rules="rules.non_empty_field_rule"
              ></v-text-field>

              <div class="d-flex align-center justify-space-between flex-wrap">
                <v-checkbox disabled label="Remember Me" hide-details
                            class="me-3 mt-1"></v-checkbox>

                <!-- forgot link -->
                <a href="javascript:void(0)" class="mt-1"> Forgot Password? </a>
              </div>
              <v-alert block type="error" dense v-if="this.require">
                {{ require_msg }}
              </v-alert>
              <v-btn block color="primary" class="mt-6" @click="login"> Login</v-btn>
            </v-form>
          </div>
          <div v-else class="text-center">
            <h2 class="font-weight-light">Validating.....</h2>
            <v-progress-circular :size="75" :width="5" color="primary"
                                 indeterminate></v-progress-circular>
          </div>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> Contact us via FB & Gmail</span>
        </v-card-text>

        <!-- social links -->
        <v-card-actions class="d-flex justify-center">
          <v-btn v-for="link in socialLink" :key="link.icon" icon class="ms-1" :href="link.url">
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289"
           src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
  import {mdiFacebook, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline} from '@mdi/js'
  import {mapActions, mapGetters} from 'vuex'

  export default {
    data() {
      return {
        isPasswordVisible: false,
        loading: false,
        require: false,

        id_no: '',
        password: '',
        require_msg: '',
        icons: {
          mdiEyeOutline,
          mdiEyeOffOutline,
        },
        socialLink: [
          {
            icon: mdiFacebook,
            color: '#4267b2',
            colorInDark: '#4267b2',
            url: "https://www.facebook.com/goodlife.itd/",
          },
          {
            icon: mdiGoogle,
            color: '#db4437',
            colorInDark: '#db4437',
            url: "https://mail.google.com/mail/u/0/#inbox",
          },
        ],
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('authentication', ['login_credentials']),
      login() {
        this.loading = true
        this.require = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id_no', this.id_no)
          data.append('password', this.password)
          this.login_credentials(data)
            .then(response => {
              if (
                response.data === 'The provided USER INFO are incorrect.' ||
                response.data === 'The USER is already Login'||
                response.data === 'Unrecognize Device'
              ) {
                this.require = true
                this.require_msg = response.data
              } else {
                this.$router.push({path: '/dashboard'})
              }
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              console.log(error)
            })
        } else {
          this.loading = false
        }
      },
    },
  }
</script>

<style lang="scss">
  @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
